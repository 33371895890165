<template>
    <div class="box">
        <p class="box__data">{{data.data}}</p>
        <p class="box__label">{{data.label}}</p>
    </div>
</template>
<script>
export default {
  props: ['data']
};
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.box {
  display: flex;
  flex-direction: column;
  gap: .3rem;
  align-items: center;
  justify-content: center;
  height: 59px;
  min-width: 162px;
  max-width: 162px;
  border-radius: 8px;
  background-color: rgba(12, 15, 74, 0.05);

   &__data {
    font-family: Mulish;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.25px;
    text-align: center;
    color: #0C0F4A;
    margin: 0;
   }

   &__label {
    font-family: Mulish;
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0.25px;
    text-align: center;
    color: rgba(12, 15, 74, 0.7);
    margin: 0;
   }
}

@media screen and (max-width: 1136px) {
  .box {
    min-width: auto;
    max-width: 100%;
    width: 100%;
  }
}
</style>
