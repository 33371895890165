<template>
    <el-card>
       <FlexColumnWithTitleLayoutSlot :title="data.title">
           <p class="description">{{data.description}}</p>
       </FlexColumnWithTitleLayoutSlot>
    </el-card>
</template>
<script>
import FlexColumnWithTitleLayoutSlot from '@/modules/insurance/components/FlexColumnWithTitleLayoutSlot.vue';

export default {
  components: {
    FlexColumnWithTitleLayoutSlot
  },
  props: ['data'],
};
</script>
<style lang="scss" scoped>
.el-card {
  border: none;
  border-radius: 8px;
  box-sizing: border-box;

  :deep(.el-card__body) {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }

  .description {
    font-family: Mulish;
    font-size: 12px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0.25px;
    text-align: left;
    color: #0C0F4A;
    margin: 0;
  }
}
</style>
