<template>
    <div class="agreement-list">
        <InsuranceAgreementCard v-for="(item, index) in agreementCount" :key="index" @on-handle-click-agreement="onHandleClickAgreement($event)"/>
    </div>
</template>
<script>
import { ref } from 'vue';
import { mapActions } from 'vuex';

import InsuranceAgreementCard from '@/modules/insurance/components/InsuranceAgreementCard.vue';
import { INSURANCES_STORE } from '@/store/modules/insurances';

export default {
  components: {
    InsuranceAgreementCard
  },
  setup() {
    // TEMPORARY ALGO FOR AGREEMENT
    const agreementCount = ref(3);
    const agreedAgreementCount = ref(0);

    const onHandleClickAgreement = (event) => {
      if (event) {
        agreedAgreementCount.value += 1;
        console.log(agreedAgreementCount.value);
      } else {
        agreedAgreementCount.value -= 1;
        console.log(agreedAgreementCount.value);
      }
    };

    return {
      agreementCount,
      agreedAgreementCount,
      onHandleClickAgreement,
    };
  },
  watch: {
    agreedAgreementCount: {
      handler() {
        if (this.agreementCount === this.agreedAgreementCount) {
          this.setAgreedAllAgreement(true);
        } else {
          this.setAgreedAllAgreement(false);
        }
      }
    }
  },
  methods: {
    ...mapActions(INSURANCES_STORE, ['setAgreedAllAgreement']),
  }
};
</script>
<style lang="scss" scoped>
.agreement-list {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 6px 6px 0 6px;
}
</style>
