<template>
    <div class="flex-column">
      <p class="title">{{title}}</p>
      <slot></slot>
    </div>
</template>
<script>
export default {
  props: ['title'],
};
</script>
<style lang="scss" scoped>
  .flex-column {
    height: 100%;
    display:flex;
    flex-direction: column;
    gap: 1.5rem;

    .title {
      font-family: Mulish;
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0.15000000596046448px;
      text-align: left;
      color: #0C0F4A;
      margin: 0;
    }
  }
</style>
