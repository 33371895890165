const __default__ = {
    props: {
        title: {
            type: String,
        },
        ctaLabel: {
            type: String,
        },
        height: {
            type: String,
            default: '272px'
        }
    },
    emits: ['open-modal'],
    setup(props, { emit }) {
        const openModal = () => {
            emit('open-modal');
        };
        return {
            openModal
        };
    }
};
import { useCssVars as _useCssVars } from 'vue';
const __injectCSSVars__ = () => {
    _useCssVars(_ctx => ({
        "562ab189": (_ctx.$props.height)
    }));
};
const __setup__ = __default__.setup;
__default__.setup = __setup__
    ? (props, ctx) => { __injectCSSVars__(); return __setup__(props, ctx); }
    : __injectCSSVars__;
export default __default__;
