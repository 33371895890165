<template>
    <div class="main-insurance-container">
        <div class="insurance-header">
           <div class="row-wrapper">
               <el-icon :size="25" color="#0C0F4A" @click="onBackJourney" v-if="getCurrentJourney > 1 && getCurrentJourney < 4"><Back/></el-icon>
               <p>{{headerTitle}}</p>
           </div>
           <p class="reminder" v-if="reminder !== ''">{{reminder}}</p>
        </div>
        <el-scrollbar>
            <div class="insurance-content">
                <keep-alive>
                 <QuoteTypeComponent @set-selected-type="setSelectedType($event)" v-if="getCurrentJourney === 1 && !getIsCompletedJourney"/>
                </keep-alive>
                <keep-alive>
                 <PersonalDetailsComponent @set-is-next-button-state="setIsNextButtonState($event)" v-if="getCurrentJourney === 2 && !getIsCompletedJourney"/>
                </keep-alive>
                <keep-alive>
                 <PropertyDetailsComponent v-if="getCurrentJourney === 3 && !getIsCompletedJourney"/>
                </keep-alive>
                <keep-alive>
                  <HomeInsuranceQuoteComponent v-if="getCurrentJourney === 4 && !getIsCompletedJourney"/>
                </keep-alive>
                <keep-alive>
                 <ApplyInsuranceComponent v-if="getCurrentJourney === 5 && !getIsCompletedJourney"/>
                </keep-alive>
                <keep-alive>
                 <EndorsementsComponent v-if="getCurrentJourney === 6 && !getIsCompletedJourney"/>
                </keep-alive>
                <InsuranceSummaryDashboardComponent v-if="getIsCompletedJourney"/>
            </div>
        </el-scrollbar>
        <div class="button-container" v-if="getCurrentJourney !== 4 && getCurrentJourney !== 5 || getAgreedAllAgreement && getCurrentJourney !== 4">
           <el-button type="primary" class="button-cta" :class="{'yellow-button': isNextButtonActive}" @click="onNextJourney" round :loading="submitting" :disabled="!isNextButtonActive" v-show="!getIsCompletedJourney">{{getCurrentJourney === 3 ? 'These are correct, Get quotes' : 'Next'}}</el-button>
           <el-button type="primary" class="button-cta" round @click="onBackJourney" v-show="!getIsCompletedJourney">BACK</el-button>
        </div>
    </div>
</template>
<script>
import { Back } from '@element-plus/icons';
import {
  ref,
  watch
} from 'vue';
import { mapActions, mapGetters } from 'vuex';

import ApplyInsuranceComponent from '@/modules/insurance/components/ApplyInsuranceComponent.vue';
import EndorsementsComponent from '@/modules/insurance/components/EndorsementsComponent.vue';
import HomeInsuranceQuoteComponent from '@/modules/insurance/components/HomeInsuranceQuoteComponent.vue';
import InsuranceSummaryDashboardComponent from '@/modules/insurance/components/InsuranceSummaryDashboardComponent.vue';
import PersonalDetailsComponent from '@/modules/insurance/components/PersonalDetailsComponent.vue';
import PropertyDetailsComponent from '@/modules/insurance/components/PropertyDetailsComponent.vue';
import QuoteTypeComponent from '@/modules/insurance/components/QuoteTypeComponent.vue';
import { INSURANCES_STORE } from '@/store/modules/insurances';

export default {
  components: {
    QuoteTypeComponent,
    PersonalDetailsComponent,
    Back,
    PropertyDetailsComponent,
    HomeInsuranceQuoteComponent,
    ApplyInsuranceComponent,
    EndorsementsComponent,
    InsuranceSummaryDashboardComponent
  },
  setup() {
    const isNextButtonActive = ref(false);
    const hasSelectedType = ref(0);
    const headerTitle = ref('What type of quote would you like?');
    const reminder = ref('');
    const width = ref('');

    const setSelectedType = (event) => {
      hasSelectedType.value = event;
    };

    const setIsNextButtonState = (event) => {
      isNextButtonActive.value = event;
    };

    watch(hasSelectedType, () => {
      if (hasSelectedType.value > 0) {
        isNextButtonActive.value = true;
      }
    });

    return {
      isNextButtonActive,
      setSelectedType,
      setIsNextButtonState,
      headerTitle,
      reminder,
      width
    };
  },
  watch: {
    'getFirstApplicantData.applicantTitle': {
      handler() {
        if (this.getFirstApplicantData.applicantTitle !== '') {
          this.isNextButtonActive = true;
        }
      }
    },
    getCurrentJourney: {
      handler() {
        if (this.getCurrentJourney === 1 && !this.getIsCompletedJourney) {
          this.headerTitle = 'What type of quote would you like?';
          this.reminder = '';
          this.width = '1030px';
        } else if (this.getCurrentJourney === 2 && !this.getIsCompletedJourney) {
          this.headerTitle = 'Here are your personal details';
          this.reminder = 'Please check if they are correct';
          this.width = '1030px';
        } else if (this.getCurrentJourney === 3 && !this.getIsCompletedJourney) {
          this.headerTitle = 'Here are the details of your property';
          this.reminder = 'Please check if they are correct';
          this.width = '1030px';
        } else if (this.getCurrentJourney === 4 && !this.getIsCompletedJourney) {
          this.headerTitle = 'Your home insurance quote';
          this.reminder = '';
          this.width = 'auto';
        } else if (this.getCurrentJourney === 5 && !this.getIsCompletedJourney) {
          this.headerTitle = 'Please check EFSDF’S eligibility before payment';
          this.reminder = '';
          this.width = 'auto';
        } else if (this.getCurrentJourney === 6 && !this.getIsCompletedJourney) {
          this.headerTitle = 'Endorsements';
          this.reminder = 'Please note that there are a number of endorsements that will apply to your policy. These special conditions vary the policy and will be shown on your schedule.';
          this.width = 'auto';
        } else if (this.getCurrentJourney === 7 && this.getIsCompletedJourney) {
          this.headerTitle = 'Quote Summary - UINH8560005';
          this.reminder = 'Quote is valid until 09 / 11 / 2022';
          this.width = 'auto';
        }
      }
    }
  },
  created() {
    if (this.getCurrentJourney < 4) {
      this.width = '1030px';
    }
  },
  computed: {
    ...mapGetters(INSURANCES_STORE, ['getCurrentJourney', 'getPreviousJourney', 'getFirstApplicantData', 'getSecondApplicantData', 'getIsApplyInsurance', 'getAgreedAllAgreement', 'getIsCompletedJourney'])
  },
  methods: {
    ...mapActions(INSURANCES_STORE, ['setCurrentJourney', 'setPreviousJourney', 'setFirstApplicantDetailsTitle', 'setSecondApplicantDetails', 'setIsApplyInsurance', 'setAgreedAllAgreement', 'setIsCompletedJourney']),

    onNextJourney() {
      this.setPreviousJourney(this.getCurrentJourney);
      if (this.getCurrentJourney !== 6 && this.getCurrentJourney < 6) {
        this.setCurrentJourney(this.getCurrentJourney + 1);
      } else {
        this.setCurrentJourney(this.getCurrentJourney + 1);
        this.setIsCompletedJourney(true);
        console.log(this.getIsCompletedJourney);
      }
    },
    onBackJourney() {
      if (this.getCurrentJourney > 1) {
        this.setCurrentJourney(this.getPreviousJourney);
        this.setPreviousJourney(this.getCurrentJourney - 1);
      }
    },
  }
};
</script>
<style lang="scss">
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.main-insurance-container {
    // max-width: 1330px;
    max-width: v-bind('width');
    height: 100%;
    margin: 0 20px 0 10px;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1.5rem;
    background: #fff;
    border-radius: 8px;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px 0px rgba(42, 42, 42, 0.02);
    padding: 1.5rem;

    .insurance-header {
        display: flex;
        flex-direction: column;
        gap: .7rem;

        .row-wrapper {
            display: flex;
            gap: .9rem;
            align-items: center;

            .el-icon {
                cursor: pointer;
            }

            p {
                font-family: Mulish;
                font-size: 24px;
                font-weight: 700;
                line-height: 30px;
                letter-spacing: 0px;
                text-align: left;
                color: #0C0F4A;
                margin: 0;
            }
        }

        .reminder {
            font-family: Mulish;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.15000000596046448px;
            text-align: left;
            color: rgba(12, 15, 74, 0.5);
            margin: 0;
            width:42%;
        }
    }

    .el-scrollbar {
        :deep(.el-scrollbar__view) {
            height: 100%;
        }

        .insurance-content {
            display: flex;
            flex-direction: column;
            padding-bottom: 2rem;
            gap: 1rem;
            overflow-x: hidden;
        }
    }

    .button-container {
        display:flex;
        gap: .5rem;
        align-items: center;

        .button-cta {
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: rgba(12, 15, 74, 0.8);
            outline: none;
            border: none;
            padding: 0 2rem;
            box-sizing: border-box;
            height: 42px;
            background: rgba(12, 15, 74, 0.05);
            font-family: Mulish;
            font-size: 15px;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: 1.25px;
            text-align: center;
        }

        .yellow-button {
            background: #FAA100;
            color: #FFF;
        }
    }
}

@include media-md-max-width() {
  .main-insurance-container {
    margin: 0 20px;
  }
}
@include media-xs-max-width() {
  .main-insurance-container {
    background: transparent;
    padding: .5rem 0 0 0;
  }
}
@media screen and (max-width: 468px) {
  .main-insurance-container {
    .button-container.flex-column {
      flex-direction: column;
      justify-content: center;

      .button-cta {
        width: 100%;
      }
    }
  }
}
@media screen and (max-width: 430px) {
  .main-insurance-container {
    .insurance-header {
        .row-wrapper {
            p {
                font-size: 18px;
                line-height: 20px;
            }
        }
        .reminder {
            width:100%;
        }
    }
  }
}
</style>
