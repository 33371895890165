<template>
    <div class="quote">
        <div class="quote__header">
            <p class="title">Cheapest quote</p>
            <p class="date">Quotes are valid until 30/07/2022</p>
        </div>
        <div class="quote__list">
          <InsuranceQuoteCardItem v-for="(item, index) in cardCount" :key="index"/>
        </div>
    </div>
</template>
<script>
import { ref } from 'vue';

import InsuranceQuoteCardItem from '@/modules/insurance/components/InsuranceQuoteCardItem.vue';

export default {
  components: {
    InsuranceQuoteCardItem
  },
  setup() {
    const cardCount = ref(5);

    return {
      cardCount
    };
  }
};
</script>
<style lang="scss" scoped>
.quote {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0 5px;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
            font-family: Mulish;
            font-size: 18px;
            font-weight: 700;
            line-height: 30px;
            letter-spacing: 0px;
            text-align: left;
            color: #0C0F4A;
            margin: 0;
        }
        .date {
            font-family: Mulish;
            font-size: 16px;
            font-weight: 400;
            line-height: 30px;
            letter-spacing: 0px;
            text-align: right;
            color: rgba(12, 15, 74, 0.5);
            margin: 0;
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: 1.2rem;
    }
}
@media screen and (max-width: 1360px) {
  .quote {
    &__list {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(385px, 1fr));
    }
  }
}
@media screen and (max-width: 443px) {
  .quote {
    &__list {
      grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    }
  }
}
</style>
