<template>
    <el-card>
        <div class="card-content">
           <inline-svg class="card-icon" :src="data.icon"></inline-svg>
           <p>{{data.label}}</p>
        </div>
    </el-card>
</template>
<script>
export default {
  props: ['data'],
};
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.el-card {
    // width: 180px;
    height: 180px;
    box-sizing: border-box;
    outline: none;
    border-radius: 10px;
    border-color: transparent;
    cursor: pointer;

    :deep(.el-card__body) {
      height: 100%;
      box-sizing: border-box;
    }

    .card-content {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: center;
        justify-content: center;
        height: 100%;

        p {
            margin: 0;
            font-family: Mulish;
            font-size: 16px;
            font-weight: 600;
            line-height: 25px;
            letter-spacing: 0.25px;
            text-align: center;
            color: #0C0F4A;
            width: v-bind('$props.data.labelWidth');
        }
    }
}

@include media-xs-max-width() {
    .el-card {
        height: 150px;
    }
}
</style>
