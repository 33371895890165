import moment from 'moment';
import { ref } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import AddSecondApplicantModal from '@/modules/insurance/components/AddSecondApplicantModal.vue';
import SummaryItemCardSlot from '@/modules/insurance/components/SummaryItemCardSlot.vue';
import { INSURANCES_STORE } from '@/store/modules/insurances';
export default {
    components: {
        SummaryItemCardSlot,
        AddSecondApplicantModal
    },
    setup() {
        const secondApplicantLabel = ref('');
        return {
            secondApplicantLabel
        };
    },
    computed: {
        ...mapGetters(INSURANCES_STORE, ['getFirstApplicantData', 'getSecondApplicantData', 'getAddSecondApplicantModal']),
        getFormattedDate() {
            return moment(this.getSecondApplicantData.dob).format('DD, MMMM YYYY');
        },
        hasSecondApplicantData() {
            if (this.getSecondApplicantData.firstName !== '' && this.getSecondApplicantData.lastName !== '' && this.getSecondApplicantData.dob !== '' && this.getSecondApplicantData.applicantTitle !== '') {
                this.secondApplicantLabel = 'change';
                return true;
            }
            return false;
        }
    },
    methods: {
        ...mapActions(INSURANCES_STORE, ['setAddSecondApplicantModal']),
    }
};
