<template>
    <div class="quote-card-details">
        <div class="image-container">
           <img src="@/assets/images/sample-insurance-image.jpg">
           <div class="cta">
                <inline-svg class="card-icon-key" :src="require(`@/assets/icons/filled-share-icon.svg`)"></inline-svg>
                <p>Share</p>
            </div>
        </div>
        <div class="details">
           <div class="wrapper">
                <p class="label">12x payments of</p>
                <p class="data">$25.00</p>
            </div>
            <p class="divider">or</p>
            <div class="wrapper">
                <p class="label">1x payments of</p>
                <p class="data">$230.00</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
};
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.quote-card-details {
    display: flex;
    gap: 4rem;
    align-items: center;
    height: 100%;

    .image-container {
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;

        .cta {
            display: none;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .details {
        display: flex;
        gap: 2rem;
        align-items: center;

        .wrapper {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: column;
            gap: .5rem;

            .label {
                font-family: Mulish;
                font-size: 14px;
                font-weight: 400;
                line-height: 25px;
                letter-spacing: 0.25px;
                text-align: center;
                color: #0C0F4A;
                margin: 0;
            }
            .divider {
                font-family: Mulish;
                font-size: 14px;
                font-weight: 400;
                line-height: 25px;
                letter-spacing: 0.25px;
                text-align: center;
                color: #0C0F4A;
                margin: 0;
            }
            .data {
                font-family: Mulish;
                font-size: 32px;
                font-weight: 700;
                line-height: 25px;
                letter-spacing: 0.25px;
                text-align: left;
                color: #0C0F4A;
                margin: 0;
            }
        }
    }
}

@media screen and (max-width: 1475px) {
  .quote-card-details {
      gap: 1rem;
  }
}

@media screen and (max-width: 1360px) {
    .quote-card-details {
        width: 100%;
        justify-content: space-between;

        .image-container {
            width: 40px;
            height: 40px;
        }
        .details {
            display: flex;
            gap: 2rem;
            align-items: center;

            .wrapper {
                .label {
                    font-size: 12px;
                }
                .data {
                    font-size: 18px;
                    line-height: 25px;
                }
            }
        }
    }
}

@include media-xs-max-width() {
    .quote-card-details {
        flex-direction: column;

        .image-container {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            img {
                width: 40px;
                height: 100%;
                object-fit: cover;
            }

            .cta {
                display: flex;
                align-items: center;
                gap: 1rem;
                cursor: pointer;

                .image-container {
                    width: 47.37px;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }

        .details {
            justify-content: space-between;
            width: 100%;
            gap: 0;
        }
    }
}
</style>
