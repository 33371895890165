<template>
    <div class="endorsement-list">
       <EndorsementItemCard v-for="(item, index) in endorsementsData" :key="index" :data="item"/>
    </div>
</template>
<script>
import { ref } from 'vue';

import EndorsementItemCard from '@/modules/insurance/components/EndorsementItemCard.vue';

export default {
  components: {
    EndorsementItemCard,
  },
  setup() {
    const endorsementsData = ref([
      {
        title: 'Flats endorsement',
        description: 'The maximum claim limit for Section 1 - Buildings represents the value of that portion of the building owned by you (including external walls, roof and foundations and such common parts of the building for which you are legally responsible). In the event of a loss resulting from an insured event to any part of the property not occupied by you but for which you are legally responsible, we will only pay such portion of that loss as the sum insured bears to the reinstatement of the building.'
      },
      {
        title: 'Jewellery and Watches clause',
        description: 'The most we will pay following theft of jewellery or watches from the home when not being worn is £10,000, unless they are kept in a fixed, locked safe.'
      },
    ]);

    return {
      endorsementsData
    };
  }
};
</script>
<style lang="scss" scoped>
.endorsement-list {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 6px 6px 0 6px;
}
</style>
