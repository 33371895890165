import { ref } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import BaseModal from '@/core/components/ui/BaseModal.vue';
import { INSURANCES_STORE } from '@/store/modules/insurances';
export default {
    components: {
        BaseModal,
    },
    setup() {
        const isAllFormFilled = ref(false);
        const applicantTitle = ref('');
        const firstName = ref('');
        const lastName = ref('');
        const dob = ref('');
        return {
            isAllFormFilled,
            applicantTitle,
            firstName,
            lastName,
            dob
        };
    },
    created() {
        this.hasSecondApplicantData();
    },
    computed: {
        ...mapGetters(INSURANCES_STORE, ['getSecondApplicantData', 'getAddSecondApplicantModal']),
    },
    methods: {
        ...mapActions(INSURANCES_STORE, ['setSecondApplicantDetails', 'setAddSecondApplicantModal']),
        cancel() {
            this.setAddSecondApplicantModal(false);
        },
        formFilledChecker() {
            if (this.applicantTitle !== '' && this.firstName !== '' && this.lastName !== '' && this.dob !== '') {
                this.isAllFormFilled = true;
            }
            else {
                this.isAllFormFilled = false;
            }
        },
        addSecondApplicant() {
            this.setSecondApplicantDetails({
                applicantTitle: this.applicantTitle,
                firstName: this.firstName,
                lastName: this.lastName,
                dob: this.dob,
            });
            this.cancel();
        },
        hasSecondApplicantData() {
            if (this.getSecondApplicantData.firstName !== '' && this.getSecondApplicantData.lastName !== '' && this.getSecondApplicantData.dob !== '' && this.getSecondApplicantData.applicantTitle !== '') {
                this.applicantTitle = this.getSecondApplicantData.applicantTitle;
                this.firstName = this.getSecondApplicantData.firstName;
                this.lastName = this.getSecondApplicantData.lastName;
                this.dob = this.getSecondApplicantData.dob;
                return true;
            }
            return false;
        }
    },
    watch: {
        applicantTitle: {
            handler() {
                this.formFilledChecker();
            }
        },
        firstName: {
            handler() {
                this.formFilledChecker();
            }
        },
        lastName: {
            handler() {
                this.formFilledChecker();
            }
        },
        dob: {
            handler() {
                this.formFilledChecker();
            }
        },
    },
};
