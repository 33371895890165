<template>
    <div class="home-insurance-quote-container">
        <div class="header-container">
           <div class="header-boxes">
              <ApplicantDetailBoxCard v-for="(item, index) in detailBoxCardData" :key="index" :data="item"/>
           </div>
           <div class="header-cta">
              <div class="cta">
                <inline-svg width="12px" height="10px" :src="require(`@/assets/icons/pen-filled-icon.svg`)"></inline-svg>
                <p>Edit details</p>
              </div>
              <div class="cta hide-in-mobile">
                <inline-svg width="12px" height="10px" :src="require(`@/assets/icons/filled-download-icon.svg`)"></inline-svg>
                <p>Download quote</p>
              </div>
           </div>
        </div>
        <div class="cta-container-mobile">
           <div class="cta-mobile">
             <p>DOWNLOAD QUOTE</p>
           </div>
           <div class="cta-mobile">
             <p>Amend Cover</p>
           </div>
        </div>
        <InsuranceSettings class="hide-in-mobile"/>
        <InsuranceQuote/>

        <!-- MODAL -->
        <PhonesAndBikesModal :show="getPhoneAndBikesModal"/>
    </div>
</template>
<script>
import { ref } from 'vue';
import { mapGetters } from 'vuex';

import ApplicantDetailBoxCard from '@/modules/insurance/components/ApplicantDetailBoxCard.vue';
import InsuranceQuote from '@/modules/insurance/components/InsuranceQuote.vue';
import InsuranceSettings from '@/modules/insurance/components/InsuranceSettings.vue';
import PhonesAndBikesModal from '@/modules/insurance/components/PhonesAndBikesModal.vue';
import { INSURANCES_STORE } from '@/store/modules/insurances';

export default {
  components: {
    ApplicantDetailBoxCard,
    InsuranceSettings,
    InsuranceQuote,
    PhonesAndBikesModal
  },
  setup() {
    const detailBoxCardData = ref([]);

    return {
      detailBoxCardData
    };
  },
  computed: {
    ...mapGetters(INSURANCES_STORE, ['getFirstApplicantData', 'getPhoneAndBikesModal'])
  },
  created() {
    this.setDetailBoxCardData();
  },
  methods: {
    setDetailBoxCardData() {
      if (this.getFirstApplicantData.applicantTitle !== '') {
        const arrayData = [
          {
            data: this.getFirstApplicantData.quoteNumber,
            label: 'Quote number'
          },
          {
            data: [this.getFirstApplicantData.applicantTitle, this.getFirstApplicantData.firstName, this.getFirstApplicantData.lastName].join(' '),
            label: 'Name'
          },
          {
            data: this.getFirstApplicantData.dob,
            label: 'Date of birth'
          },
          {
            data: this.getFirstApplicantData.postcode,
            label: 'Postcode'
          },
        ];
        this.detailBoxCardData = arrayData;
      }
    },
  }
};
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.home-insurance-quote-container {
   height: 100%;
   display: flex;
   flex-direction: column;
   gap: 2rem;

   .header-container {
       display: flex;
       align-items: center;
       justify-content: space-between;
       gap: 1.5rem;

       .header-boxes {
          display: flex;
          align-items: center;
          gap: 1rem;
       }
       .header-cta {
          display: flex;
          align-items: center;
          gap: 2rem;

         .cta {
            display: flex;
            align-items: center;
            gap: .5rem;
            cursor: pointer;

            p {
                font-family: Mulish;
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0.15000000596046448px;
                text-align: left;
                color: #0C0F4A;
                margin: 0;
            }
         }
       }
   }
   .cta-container-mobile {
     display: none;
   }
}

@media screen and (max-width: 1476px) {
  .home-insurance-quote-container {
    .header-container {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }
}
@media screen and (max-width: 1136px) {
  .home-insurance-quote-container {
    .header-container {
      width: 100%;
      .header-boxes {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
        width: 100%;
      }
    }
  }
}
@include media-xs-max-width() {
  .home-insurance-quote-container {
    .header-container {
      width: auto;
      background: rgba(12, 15, 74, 0.05);
      border-radius: 8px;
      padding: 1rem;
      .header-cta {
        justify-content: center;
        width: 100%;
      }
    }
    .cta-container-mobile {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;

      .cta-mobile {
        width: 100%;
        height: 42px;
        border-radius: 8px;
        background: rgba(12, 15, 74, 0.05);
        display: flex;
        align-items:center;
        justify-content: center;
        cursor: pointer;

        p {
          font-family: Mulish;
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          letter-spacing: 1.25px;
          text-align: center;
          color: rgba(12, 15, 74, 0.8);
          margin: 0;
        }
      }
    }
  }
  .hide-in-mobile {
    display: none !important;
  }
}
</style>
