<template>
    <div class="property-details-container">
        <div class="column">
           <div class="input-container">
              <p class="label">Risk address</p>
              <p class="detail">33 Collier Close, Epsom, KT19 9JG</p>
           </div>
           <div class="input-container">
              <p class="label">Numbers of bedrooms</p>
              <div class="data-container" v-if="!inputOne" @click="onHandleClick(1)">
                  <p class="data">{{propertyDetails.numOfBedrooms}}</p>
                  <inline-svg class="edit-cta" :src="require(`@/assets/icons/pen-filled-icon.svg`)"></inline-svg>
              </div>
              <el-input v-model="propertyDetails.numOfBedrooms" v-if="inputOne" @blur="reset"></el-input>
           </div>
           <div class="input-container">
              <p class="label">Type of property</p>
              <div class="data-container" v-if="!inputTwo" @click="onHandleClick(2)">
                  <p class="data">{{propertyDetails.typeOfProperty}}</p>
                  <inline-svg class="edit-cta" :src="require(`@/assets/icons/pen-filled-icon.svg`)"></inline-svg>
              </div>
              <el-input v-model="propertyDetails.typeOfProperty" v-if="inputTwo" @blur="reset"></el-input>
           </div>
           <div class="input-container">
              <p class="label">Year of construction</p>
              <div class="data-container" v-if="!inputThree" @click="onHandleClick(3)">
                  <p class="data">{{propertyDetails.yrOfConstruction}}</p>
                  <inline-svg class="edit-cta" :src="require(`@/assets/icons/pen-filled-icon.svg`)"></inline-svg>
              </div>
              <el-input v-model="propertyDetails.yrOfConstruction" v-if="inputThree" @blur="reset"></el-input>
           </div>
        </div>
    </div>
</template>
<script>
import { ref } from 'vue';

export default {
  setup() {
    const inputOne = ref(false);
    const inputTwo = ref(false);
    const inputThree = ref(false);
    const propertyDetails = ref(
      {
        numOfBedrooms: 2,
        typeOfProperty: 'Flat or Maisonette',
        yrOfConstruction: 'Between 1946 and 1959'
      }
    );

    const onHandleClick = (id) => {
      if (id === 1) {
        inputOne.value = true;
        inputTwo.value = false;
        inputThree.value = false;
      } else if (id === 2) {
        inputOne.value = false;
        inputTwo.value = true;
        inputThree.value = false;
      } else if (id === 3) {
        inputOne.value = false;
        inputTwo.value = false;
        inputThree.value = true;
      }
    };

    const reset = () => {
      inputOne.value = false;
      inputTwo.value = false;
      inputThree.value = false;
    };

    return {
      propertyDetails,
      inputOne,
      inputTwo,
      inputThree,
      onHandleClick,
      reset
    };
  }
};
</script>
<style lang="scss" scoped>
.property-details-container {
    height: 100%;
    display: flex;
    flex-direction: column;

    .column {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        .input-container {
            display: flex;
            flex-direction: column;
            gap: .7rem;

            .label {
                font-family: Mulish;
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0.15000000596046448px;
                text-align: left;
                color: #0C0F4A;
                margin: 0;
            }

            .detail {
                font-family: Mulish;
                font-size: 18px;
                font-weight: 500;
                line-height: 22px;
                letter-spacing: 0.25px;
                text-align: left;
                color: #0C0F4A;
                margin: 0;
            }

            .data-container {
                display: flex;
                max-width: 490px;
                gap: .7rem;
                align-items: center;
                border-bottom: 2px solid rgba(12, 15, 74, 0.2);
                padding-bottom: .4rem;
                cursor: pointer;
                padding-top: 0.6rem;

                .data {
                    font-family: Mulish;
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 22px;
                    letter-spacing: 0.25px;
                    text-align: left;
                    color: #0C0F4A;
                    margin: 0;
                }
            }

            .el-input {
                max-width: 490px;
                :deep(.el-input__inner) {
                    background: transparent;
                    border: none;
                    color: #0C0F4A;
                    border-bottom: 3px solid rgba(12, 15, 74, 0.2);
                    font-family: Mulish;
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 22px;
                    letter-spacing: 0.25px;
                    text-align: left;
                    padding: 0;
                }
            }
        }
    }
}
</style>
