import { Select } from '@element-plus/icons';
import { ref } from 'vue';
import { mapActions } from 'vuex';
import BaseModal from '@/core/components/ui/BaseModal.vue';
import { INSURANCES_STORE } from '@/store/modules/insurances';
export default {
    components: {
        BaseModal,
        Select
    },
    emits: ['close'],
    props: {
        show: Boolean,
    },
    setup() {
        const selectedValue = ref(null);
        const inputDescription = ref('');
        const costReplacement = ref('');
        const submitting = ref(false);
        const select = (value) => {
            selectedValue.value = value;
        };
        return {
            selectedValue,
            inputDescription,
            costReplacement,
            submitting,
            select
        };
    },
    methods: {
        ...mapActions(INSURANCES_STORE, ['setPhoneAndBikesModal']),
        cancel() {
            this.setPhoneAndBikesModal(false);
        },
        submit() {
            this.setPhoneAndBikesModal(false);
        }
    }
};
