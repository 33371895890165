<template>
    <div class="personal-details-container">
        <div class="column">
           <div class="input-container">
              <p class="label">First applicant title</p>
              <el-radio-group v-model="getFirstApplicantData.applicantTitle" v-if="!isAddSecondApplicant">
                <el-radio label="Mr" size="large">Mr</el-radio>
                <el-radio label="Mrs" size="large">Mrs</el-radio>
                <el-radio label="Miss" size="large">Miss</el-radio>
                <el-radio label="Ms" size="large">Ms</el-radio>
              </el-radio-group>
              <p class="detail" v-if="getFirstApplicantData.applicantTitle !== '' && isAddSecondApplicant">{{getFirstApplicantData.applicantTitle}}</p>
           </div>
           <div class="input-container">
              <p class="label">First name</p>
              <p class="detail">{{getFirstApplicantData.firstName}}</p>
           </div>
           <div class="input-container">
              <p class="label">Last name</p>
              <p class="detail">{{getFirstApplicantData.lastName}}</p>
           </div>
           <div class="input-container">
              <p class="label">Date of birth</p>
              <p class="detail">{{getFirstApplicantData.dob}}</p>
           </div>
           <div class="cta add" :class="{'not-allowed': getFirstApplicantData.applicantTitle === ''}" @click="showAddApplicantForm" v-show="!isAddSecondApplicant">
              <el-icon :size="15" color="#0C0F4A"><CirclePlusFilled/></el-icon>
              <p>Add second applicant</p>
           </div>
        </div>
        <div class="column" v-if="isAddSecondApplicant">
           <div class="input-container">
              <p class="label">second applicant title</p>
              <el-radio-group v-model="secondApplicantTitle">
                <el-radio label="Mr" size="large">Mr</el-radio>
                <el-radio label="Mrs" size="large">Mrs</el-radio>
                <el-radio label="Miss" size="large">Miss</el-radio>
                <el-radio label="Ms" size="large">Ms</el-radio>
              </el-radio-group>
           </div>
           <div class="input-container">
               <p class="label">First name</p>
               <el-input v-model="secondApplicantFirstName"></el-input>
           </div>
           <div class="input-container">
              <p class="label">Last name</p>
              <el-input v-model="secondApplicantLastName"></el-input>
           </div>
           <div class="input-container">
              <p class="label">Date of birth</p>
              <el-date-picker v-model="secondApplicantDob" type="date" format="DD/MM/YYYY" placeholder="DD/MM/YYYY" />
           </div>
           <div class="cta remove" @click="removeSecondApplicantForm">
              <el-icon :size="15" color="rgba(12, 15, 74, 0.5)"><RemoveFilled/></el-icon>
              <p>Remove second applicant</p>
           </div>
        </div>
    </div>
</template>
<script>
import { CirclePlusFilled, RemoveFilled } from '@element-plus/icons';
import { ref } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import { INSURANCES_STORE } from '@/store/modules/insurances';

export default {
  components: {
    CirclePlusFilled,
    RemoveFilled
  },
  emits: ['set-is-next-button-state'],
  setup(props, { emit }) {
    const isAddSecondApplicant = ref(false);
    const isSecondApplicantFormComplete = ref(false);
    const secondApplicantTitle = ref('');
    const secondApplicantFirstName = ref('');
    const secondApplicantLastName = ref('');
    const secondApplicantDob = ref('');

    const setIsNextButtonState = (event) => {
      emit('set-is-next-button-state', event);
    };

    return {
      isAddSecondApplicant,
      setIsNextButtonState,
      isSecondApplicantFormComplete,
      secondApplicantTitle,
      secondApplicantFirstName,
      secondApplicantLastName,
      secondApplicantDob
    };
  },
  watch: {
    secondApplicantTitle: {
      handler() {
        this.secondApplicantFormChecker();
      }
    },
    secondApplicantFirstName: {
      handler() {
        this.secondApplicantFormChecker();
      }
    },
    secondApplicantLastName: {
      handler() {
        this.secondApplicantFormChecker();
      }
    },
    secondApplicantDob: {
      handler() {
        this.secondApplicantFormChecker();
      }
    },
    isSecondApplicantFormComplete: {
      handler() {
        if (this.isSecondApplicantFormComplete) {
          this.setSecondApplicantDetails({
            applicantTitle: this.secondApplicantTitle,
            firstName: this.secondApplicantFirstName,
            lastName: this.secondApplicantLastName,
            dob: this.secondApplicantDob,
          });
        }
      }
    }
  },
  created() {
    this.secondApplicantFormChecker();
  },
  computed: {
    ...mapGetters(INSURANCES_STORE, ['getCurrentJourney', 'getPreviousJourney', 'getFirstApplicantData', 'getSecondApplicantData'])
  },
  methods: {
    ...mapActions(INSURANCES_STORE, ['setCurrentJourney', 'setPreviousJourney', 'setFirstApplicantDetailsTitle', 'setSecondApplicantDetails']),

    showAddApplicantForm() {
      if (this.getFirstApplicantData.applicantTitle !== '') {
        this.secondApplicantFormChecker();
        this.isAddSecondApplicant = true;
      } else {
        this.isAddSecondApplicant = false;
      }
    },

    removeSecondApplicantForm() {
      this.isSecondApplicantFormComplete = false;
      this.isAddSecondApplicant = false;
      this.setIsNextButtonState(true);
    },

    secondApplicantFormChecker() {
      if (this.secondApplicantTitle !== '' && this.secondApplicantFirstName !== '' && this.secondApplicantLastName !== '' && this.secondApplicantDob !== '') {
        this.setIsNextButtonState(true);
        this.isSecondApplicantFormComplete = true;
      } else {
        this.setIsNextButtonState(false);
        this.isSecondApplicantFormComplete = false;
      }
    }
  },
};
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.personal-details-container {
    height: 100%;
    display: flex;
    gap: 3rem;

    .column {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        .input-container {
            display: flex;
            flex-direction: column;
            gap: .7rem;

            .el-radio-group {
                .el-radio {
                    :deep(.el-radio__input.is-checked .el-radio__inner::after) {
                       background-color: #FFA500;
                    }
                    :deep(.el-radio__label) {
                        font-family: Mulish;
                        font-size: 18px;
                        font-weight: 500;
                        line-height: 22px;
                        letter-spacing: 0.25px;
                        text-align: left;
                        color: #0C0F4A;
                    }
                }
            }

            .el-input {
                :deep(.el-input__inner) {
                    background: transparent;
                    border: none;
                    color: #0C0F4A;
                    border-bottom: 2px solid rgba(12, 15, 74, 0.2);
                    font-family: Mulish;
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 22px;
                    letter-spacing: 0.25px;
                    text-align: left;
                    padding: 0;
                }
            }

            :deep(.el-date-editor.el-input) {
              border: none;
              border-bottom: 2px solid rgba(12, 15, 74, 0.2);

              .el-input__inner {
                  border-color: transparent;
                  background: transparent;
              }
            }

            .label {
                font-family: Mulish;
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0.15000000596046448px;
                text-align: left;
                color: #0C0F4A;
                margin: 0;
            }

            .detail {
                font-family: Mulish;
                font-size: 18px;
                font-weight: 500;
                line-height: 22px;
                letter-spacing: 0.25px;
                text-align: left;
                color: #0C0F4A;
                margin: 0;
            }
        }
        .cta {
            display: flex;
            gap: .5rem;
            cursor: pointer;
            align-items: center;

            p {
                font-family: Mulish;
                font-size: 14px;
                font-weight: 700;
                line-height: 22px;
                letter-spacing: 0.25px;
                text-align: left;
                margin: 0;
            }
        }

        .not-allowed {
            cursor: not-allowed;
        }

        .cta.add {
            p {
                color: #0C0F4A;
            }
        }
        .cta.remove {
            p {
                color: rgba(12, 15, 74, 0.5);
            }
        }
    }
}

@include media-xs-max-width() {
  .personal-details-container {
    flex-direction: column;
  }
}
</style>
