<template>
    <el-card>
        <div class="item-content">
           <QuoteCardDetails/>
           <div class="cta-wrapper">
              <div class="cta-container">
                    <div class="cta">
                        <div class="image-container">
                            <img src="@/assets/images/5-star-rating.jpg">
                        </div>
                        <p>Compare</p>
                    </div>
                    <div class="cta hide-in-mobile">
                        <inline-svg class="card-icon-key" :src="require(`@/assets/icons/filled-share-icon.svg`)"></inline-svg>
                        <p>Share</p>
                    </div>
                </div>
                <el-button type="primary" class="yellow-button" round :loading="submitting" @click="onNextJourney">Apply</el-button>
           </div>
        </div>
    </el-card>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import QuoteCardDetails from '@/modules/insurance/components/QuoteCardDetails.vue';
import { INSURANCES_STORE } from '@/store/modules/insurances';

export default {
  components: {
    QuoteCardDetails
  },
  computed: {
    ...mapGetters(INSURANCES_STORE, ['getCurrentJourney', 'getPreviousJourney', 'getIsApplyInsurance'])
  },
  methods: {
    ...mapActions(INSURANCES_STORE, ['setCurrentJourney', 'setPreviousJourney', 'setIsApplyInsurance']),

    onNextJourney() {
      this.setPreviousJourney(this.getCurrentJourney);
      //   if (this.getCurrentJourney !== 4 && this.getCurrentJourney < 4) {
      this.setCurrentJourney(this.getCurrentJourney + 1);
      //   }
      //   this.setIsApplyInsurance(true);
      console.log(this.getCurrentJourney);
      console.log(this.getPreviousJourney);
    },
  }
};
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.el-card {
    height: 116px;
    box-sizing: border-box;
    border-radius: 8px;
    border: none;

    :deep(.el-card__body) {
        box-sizing: border-box;
        width:100%;
        height: 100%;
    }
}
.item-content {
    height: 100%;
    display: flex;
    justify-content: space-between;
    gap: 3rem;
    align-items: center;
    padding-left: 2rem ;
    padding-right: 1rem;

    .cta-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 2rem;
        width: 50%;
      .cta-container {
            display: flex;
            align-items: center;
            gap: 4rem;

            .cta {
                display: flex;
                align-items: center;
                gap: 1rem;
                cursor: pointer;

                .image-container {
                    width: 47.37px;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }

        .yellow-button {
            display: flex;
            align-items: center;
            justify-content: center;
            outline: none;
            border: none;
            padding: 0 2rem;
            box-sizing: border-box;
            height: 42px;
            font-family: Mulish;
            font-size: 15px;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: 1.25px;
            text-align: center;
            background: #FAA100;
            color: #FFF;
        }
    }
}
@media screen and (max-width: 1475px) {
  .el-card {
  }
  .item-content {
      padding: 0;
      gap: 2rem;
  }
}
@media screen and (max-width: 1360px) {
  .el-card {
    height: auto;
  }
  .item-content {
    flex-direction: column;
    align-items: flex-start;

    .cta-wrapper {
        width: 100%;
        justify-content: space-between;
        .cta-container {
            gap: 1rem;
        }
    }
  }
}

@include media-xs-max-width() {
    .hide-in-mobile {
        display: none !important;
    }
    .item-content {
    .cta-wrapper {
        gap: 0;
    }
  }
}
</style>
