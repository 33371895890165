<template>
    <el-card>
        <div class="agreement-card-content">
            <p class="card-header">The property</p>
            <ul>
                <li>Will be permanently occupied by you and your family and by no more than one lodger</li>
                <li>Will be maintained in a good state of repair</li>
                <li>Has a roof which is built from slate or tiles</li>
                <li>Has walls which are built from brick (which includes brick clad with timber frame properties built from 1980 onwards), stone or concrete (excluding prefabricated concrete)</li>
            </ul>
            <div class="cta-container">
                <el-button type="primary" class="button-cta" :class="{'yellow-button': isAgree}" @click="onAgreeHandleClick" round :loading="submitting">Agree</el-button>
                <el-button type="primary" class="button-cta" @click="onDisagreeHandleClick" round :loading="submitting">Disagree</el-button>
            </div>
        </div>
    </el-card>
</template>
<script>
import { ref } from 'vue';

export default {
  emits: ['on-handle-click-agreement'],
  setup(props, { emit }) {
    const isAgree = ref(false);

    const onAgreeHandleClick = () => {
      if (isAgree.value === false) {
        isAgree.value = true;
        emit('on-handle-click-agreement', true);
      }
    };

    const onDisagreeHandleClick = () => {
      if (isAgree.value === true) {
        isAgree.value = false;
        emit('on-handle-click-agreement', false);
      }
    };

    return {
      isAgree,
      onAgreeHandleClick,
      onDisagreeHandleClick
    };
  },
  methods: {
  }
};
</script>
<style lang="scss" scoped>
.el-card {
  border-radius: 8px;
  border: none;
  box-sizing: border-box;

  :deep(.el-card__body) {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
  }

  .agreement-card-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1.5rem;

    .card-header {
        font-family: Mulish;
        font-size: 18px;
        font-weight: 700;
        line-height: 25px;
        letter-spacing: 0.25px;
        text-align: left;
        color: #0C0F4A;
        margin: 0;
    }

    ul {
        margin: 0;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        li {
            font-family: Mulish;
            font-size: 12px;
            font-weight: 400;
            line-height: 25px;
            letter-spacing: 0.25px;
            text-align: left;
            color: #0C0F4A;
            margin:0;
        }
    }

    .cta-container {
        display:flex;
        gap: .5rem;
        align-items: center;

        .button-cta {
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: rgba(12, 15, 74, 0.8);
            outline: none;
            border: none;
            padding: 0 2rem;
            box-sizing: border-box;
            height: 42px;
            background: rgba(12, 15, 74, 0.05);
            font-family: Mulish;
            font-size: 15px;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: 1.25px;
            text-align: center;
        }

        .yellow-button {
            background: #FAA100;
            color: #FFF;
        }
    }
  }
}
</style>
