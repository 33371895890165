<template>
    <div class="type">
        <FlexColumnWithTitleLayoutSlot title="Home insurance">
          <div class="type__content">
            <QuoteTypeCardItem v-for="(item, index) in homeInsuranceData" :key="index" :data="item" :class="{'is-active': activeId === item.id}" @click="changeActiveId(item.id)"/>
          </div>
        </FlexColumnWithTitleLayoutSlot>
        <FlexColumnWithTitleLayoutSlot title="Other products">
          <div class="type__content">
            <QuoteTypeCardItem v-for="(item, index) in otherProductsData" :key="index" :data="item" :class="{'is-active': activeId === item.id}" @click="changeActiveId(item.id)"/>
          </div>
        </FlexColumnWithTitleLayoutSlot>
    </div>
</template>
<script>
import { ref } from 'vue';

import FlexColumnWithTitleLayoutSlot from '@/modules/insurance/components/FlexColumnWithTitleLayoutSlot.vue';
import QuoteTypeCardItem from '@/modules/insurance/components/QuoteTypeCardItem.vue';

export default {
  components: {
    FlexColumnWithTitleLayoutSlot,
    QuoteTypeCardItem
  },
  emits: ['set-selected-type'],
  setup(props, { emit }) {
    const homeInsuranceData = ref([
      /* eslint-disable global-require */
      {
        iconActive: `${require('@/assets/icons/home-yellow-filled-icon.svg')}`, icon: `${require('@/assets/icons/home-filled-icon.svg')}`, label: 'Buildins & Contents', labelWidth: '60%', id: 1
      },
      {
        iconActive: `${require('@/assets/icons/home-yellow-filled-icon.svg')}`, icon: `${require('@/assets/icons/skeleton-home-filled-icon.svg')}`, label: 'Buildins only', labelWidth: '45%', id: 2
      },
      {
        iconActive: `${require('@/assets/icons/home-yellow-filled-icon.svg')}`, icon: `${require('@/assets/icons/couch-filled-icon.svg')}`, label: 'Contents only', labelWidth: '50%', id: 3
      },
      /* eslint-enable global-require */
    ]);

    const otherProductsData = ref([
      /* eslint-disable global-require */
      {
        iconActive: `${require('@/assets/icons/home-yellow-filled-icon.svg')}`, icon: `${require('@/assets/icons/buildings-filled-icon.svg')}`, label: 'Non-Standard Home Insurance', labelWidth: '85%', id: 4
      },
      {
        iconActive: `${require('@/assets/icons/home-yellow-filled-icon.svg')}`, icon: `${require('@/assets/icons/key-filled-icon.svg')}`, label: 'BTL/Landlords Insurance', id: 5
      },
      /* eslint-enable global-require */
    ]);
    const activeId = ref(null);
    const changeActiveId = (id) => {
      activeId.value = id;
      emit('set-selected-type', id);
    };

    return {
      homeInsuranceData,
      otherProductsData,
      activeId,
      changeActiveId
    };
  }
};
</script>
<style lang="scss" scoped>
.type {
    height: 100%;
    // width: 100%;
    display:flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 .5rem;

    &__content {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
      gap: 1.5rem;
      align-items: center;

      .is-active {
        border: 4px solid #FAA100;
      }
    }
}
</style>
