<template>
    <div class="settings">
        <el-card>
            <div class="settings__content">
                <div class="settings__row">
                    <div class="settings__column">
                        <el-checkbox-group v-model="selectedInsurancesColumnOne">
                            <el-checkbox label="Building cover" />
                            <el-checkbox label="Accidental damage" />
                        </el-checkbox-group>
                        <div class="column-detail-container">
                        <p class="label">Excess</p>
                        <el-select v-model="excessOptionsValueColumnOne" class="m-2 width-80" placeholder="Select" size="small">
                                <el-option
                                v-for="item in excessOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                                />
                            </el-select>
                        </div>
                        <div class="column-detail-container">
                        <p class="label">No claims discount</p>
                        <el-select v-model="noClaimsDiscountOptionsValue" class="m-2 width-120" placeholder="Select" size="small">
                                <el-option
                                v-for="item in noClaimsDiscountOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                                />
                            </el-select>
                        </div>
                    </div>
                    <el-divider direction="vertical" />
                </div>

                <div class="settings__row">
                    <div class="settings__column">
                        <el-checkbox-group v-model="selectedInsurancesColumnTwo">
                            <el-checkbox label="Contents cover" />
                            <el-checkbox label="Accidental damage" />
                        </el-checkbox-group>
                        <div class="column-detail-container">
                        <p class="label">Excess</p>
                        <el-select v-model="excessOptionsValueColumnTwo" class="m-2 width-80" placeholder="Select" size="small">
                                <el-option
                                v-for="item in excessOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                                />
                            </el-select>
                        </div>
                        <div class="column-detail-container">
                        <p class="label">No claims discount</p>
                        <el-select v-model="noClaimsDiscountOptionsValue" class="m-2 width-120" placeholder="Select" size="small">
                                <el-option
                                v-for="item in noClaimsDiscountOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                                />
                            </el-select>
                        </div>
                    </div>
                    <el-divider direction="vertical" />
                </div>

                <div class="settings__row">
                    <div class="settings__column">
                        <el-checkbox-group v-model="selectedInsurancesColumnThree">
                            <el-checkbox label="Possessions" />
                        </el-checkbox-group>
                        <div class="column-detail-container">
                            <p class="label">Phones & bikes</p>
                            <div class="add-cta-container" @click="openPhoneAndBikesModal">
                                <p class="add-cta-label">Add</p>
                                <el-icon :size="12" color="#0C0F4A"><Plus/></el-icon>
                            </div>
                        </div>
                        <div class="column-detail-container">
                            <p class="label">Items valued over $2500</p>
                            <div class="add-cta-container">
                            <p class="add-cta-label">Add</p>
                            <el-icon :size="12" color="#0C0F4A"><Plus/></el-icon>
                            </div>
                        </div>
                        <div class="column-detail-container">
                        <p class="label">Other items taking outside the home</p>
                        <el-select v-model="excessOptionsValueColumnThree" class="m-2 width-80" placeholder="Select" size="small">
                                <el-option
                                v-for="item in excessOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                                />
                            </el-select>
                        </div>
                    </div>
                    <el-divider direction="vertical" />
                </div>

                <div class="settings__row">
                    <div class="settings__column">
                        <el-checkbox-group v-model="selectedInsurancesColumnFour">
                            <el-checkbox label="Building cover" />
                            <el-checkbox label="Home Emergency" />
                            <el-checkbox label="Family legal expenses" />
                        </el-checkbox-group>
                    </div>
                </div>
            </div>
        </el-card>
        <el-button type="primary" class="settings__refresh" @click="refresh" round :loading="submitting">Refresh</el-button>
    </div>
</template>
<script>
import { Plus } from '@element-plus/icons';
import { ref } from 'vue';
import { mapActions } from 'vuex';

import { INSURANCES_STORE } from '@/store/modules/insurances';

export default {
  components: {
    Plus,
  },
  setup() {
    // TEMPORARY ALGO FOR SELECTING INSURANCE
    const selectedInsurancesColumnOne = ref([]);
    const selectedInsurancesColumnTwo = ref([]);
    const selectedInsurancesColumnThree = ref([]);
    const selectedInsurancesColumnFour = ref([]);
    const excessOptionsValueColumnOne = ref('100');
    const excessOptionsValueColumnTwo = ref('100');
    const excessOptionsValueColumnThree = ref('100');
    const noClaimsDiscountOptionsValue = ref('9');
    const excessOptions = ref([
      {
        value: '100',
        label: '$100',
      },
      {
        value: '150',
        label: '$150',
      },
      {
        value: '200',
        label: '$200',
      },
      {
        value: '250',
        label: '$250',
      },
      {
        value: '300',
        label: '$300',
      },
    ]);
    const noClaimsDiscountOptions = ref([
      {
        value: '9',
        label: '9+ discount',
      },
    ]);

    const refresh = () => {
      selectedInsurancesColumnOne.value = [];
      selectedInsurancesColumnTwo.value = [];
      selectedInsurancesColumnThree.value = [];
      selectedInsurancesColumnFour.value = [];
      excessOptionsValueColumnOne.value = '100';
      excessOptionsValueColumnTwo.value = '100';
      excessOptionsValueColumnThree.value = '100';
    };

    return {
      selectedInsurancesColumnOne,
      selectedInsurancesColumnTwo,
      selectedInsurancesColumnThree,
      selectedInsurancesColumnFour,
      excessOptions,
      excessOptionsValueColumnOne,
      excessOptionsValueColumnTwo,
      excessOptionsValueColumnThree,
      noClaimsDiscountOptions,
      noClaimsDiscountOptionsValue,
      refresh
    };
  },
  methods: {
    ...mapActions(INSURANCES_STORE, ['setPhoneAndBikesModal']),

    openPhoneAndBikesModal() {
      this.setPhoneAndBikesModal(true);
      console.log(this.setPhoneAndBikesModal);
    }
  },
};
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.settings {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 5px;

    .el-card {
        border-radius: 8px;
        box-sizing: border-box;

        :deep(.el-card__body) {
            width: 100%;
            height: 100%;
            box-sizing: border-box;
        }
    }

    &__content {
        display: flex;
        justify-content: space-between;
        gap: 1rem;

        .el-divider {
            margin:0;
            height: auto;
            width: 2px;
        }
    }

    &__row {
        display: flex;
        justify-content: space-between;
        gap: 1rem;
        width: 350px;
    }

    &__column {
        display : flex;
        flex-direction: column;
        gap: .5rem;
        width: 100%;

        .el-checkbox-group {
            .el-checkbox {
                display: flex;
                flex-direction: row-reverse;
                justify-content: space-between;
                width: 96%;
                gap: 2rem;

                :deep(.el-checkbox__input.is-checked .el-checkbox__inner) {
                    background-color: #FFA500;
                    border-color: #FFA500;
                }

                :deep(.el-checkbox__label) {
                    font-family: Mulish;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 25px;
                    letter-spacing: 0.25px;
                    text-align: left;
                    color: #0C0F4A;
                    padding: 0;
                }
            }
        }

        .column-detail-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 1rem;

            .label {
                font-family: Mulish;
                font-size: 12px;
                font-weight: 400;
                line-height: 25px;
                letter-spacing: 0.25px;
                text-align: left;
                color: #0C0F4A;
                margin: 0;
            }

            .el-select.width-80 {
                width: 80px;
                min-width: 80px;
            }
            .el-select.width-120 {
                width: 120px;
                min-width: 120px;
            }
            :deep(.el-input__inner) {
                border: none;
                outline: none;
            }
            :deep(.el-select__caret.el-icon) {
                color: #0C0F4A;
            }

            .add-cta-container {
                display: flex;
                gap: .4rem;
                align-items: center;
                margin-right: .8rem;
                cursor: pointer;

                .add-cta-label {
                    font-family: Mulish;
                    font-size: 12px;
                    font-weight: 700;
                    line-height: 25px;
                    letter-spacing: 0.25px;
                    text-align: right;
                    color: #0C0F4A;
                    margin: 0;
                }
            }
        }
    }

    &__refresh {
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none;
        border: none;
        padding: 0 2rem;
        box-sizing: border-box;
        height: 42px;
        font-family: Mulish;
        font-size: 15px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 1.25px;
        text-align: center;
        background: #FAA100;
        color: #FFF;
    }
}

@media screen and (max-width: 1421px) {
    .settings {
        &__content {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
            gap: 1rem;
        }
        &__row {
            width: 100%;
        }
    }
    .el-divider {
        display: none;
    }
}
</style>
